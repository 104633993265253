@import 'font';
@import 'colors';
@import 'core';
@import 'variables';

html {
  height: 100%;
}
body {
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.MuiButton-root {
  text-transform: none !important;
  font-weight: unset !important;
}

.MuiTypography-h5 {
  font-weight: 500 !important;
}

.MuiLink-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.GridLayout {
  display: grid;
  padding: 16px;

  & .Vertical {
    grid-template-columns: 100%;
  }
}

.FlexLayout {
  display: flex;
  padding: 16px;
}

::-webkit-scrollbar {
  height: 0.9em !important;
  background-color: rgba(5, 6, 14, 0.24);
  width: 0.9em !important;
  
}