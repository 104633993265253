.Login {
  background-color: #c3c3c3;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .SmallButton {
    font-size: 0.65rem !important;
    color: #ffffff;
  }

  .LoginBoxRoot {
    border-radius: 0.3rem;
    max-height: 100vh;
    margin: auto;
    background-color: var(--color-bg-primary);
    display: flex;
    text-align: center;
    flex-direction: column;
    min-height: 60vh;
    max-height: 98vh;
    width: 420px;
    .MuiTypography-root {
      letter-spacing: 0em !important;
    }
  }

  .LoginBox {
    display: flex;
    text-align: center;
    height: 100%;
    overflow: auto;
  }
  .LoginCheckout {
    padding: 0px 24px 32px;
    width: 100%;
  }
  span {
    font-weight: 1000;
  }
  .line {
    border-right: 1px solid var(--border-color);
  }

  .MainComponent {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 358px;
    .PaymentOptions {
      border-radius: 0px;
      width: 100%;
      overflow: auto;
      // background-color: var(--color-bg-primary);
    }
  }
  .redirectButton {
    margin: auto;
  }
  .timer {
    width: 80%;
    margin: 0.5rem auto;
  }

  @media (max-width: 540px) {
    height: unset;
    width: unset;
    justify-content: start;
    .LoginBoxRoot {
      max-height: unset;
      min-height: unset;
      border-width: 0px;
      height: unset;
      width: unset;
      flex-direction: column;
      position: relative;
      transform: unset;
      overflow: visible;
      border-radius: 0px;
      margin: unset;
    }
    .LoginBox {
      padding: 0px 8px;
    }
    .LoginCheckout {
      width: 100%;
      padding: 0px 8px 16px;
      overflow: unset;
    }
    .MainComponent {
      margin-left: auto;
      margin-right: auto;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }

  .question {
    margin-top: 4px;
    padding: 4px 0px;
  }

  @media (max-width: 375px) {
    .LoginCheckout {
      padding: 8px;
    }
  }
}
@media (max-width: 540px) {
  html {
    background-color: var(--color-bg-primary);
  }
}

.Footer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 16px;
  padding: 4px;
  margin-top: 24px;
  align-items: center;
  font-size: 13px;
  font-weight: 400;

  img {
    height: 22px;
    width: 22px;
    margin-right: 6px;
  }
}
