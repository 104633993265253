@font-face {
  font-family: CalibriRegular;
  src: url('./fonts/calibri/Calibri-Regular.ttf') format('truetype');
}

@font-face {
  font-family: CalibriBold;
  src: url('./fonts/calibri/Calibri-Bold.TTF') format('truetype');
}

@font-face {
  font-family: LeelawadeeUiRegular;
  src: url('./fonts/leelawadee-ui/LeelawUI.ttf') format('truetype');
}

@font-face {
  font-family: LeelawadeeUiBold;
  src: url('./fonts/leelawadee-ui/leelawadee-ui-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/material-icon/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

:root {
  --font-family-calibri-regular: CalibriRegular, sans-serif;
  --font-family-calibri-bold: CalibriBold, sans-serif;
  --font-family-leela-regular: LeelawadeeUiRegular, sans-serif;
  --font-family-leela-bold: LeelawadeeUiBold, sans-serif;
  --font-family-segoe: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  --font-family-roboto: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
