
.RapydPage {
  $self: &;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__card{
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.12), 0px 1px 3px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
    border-radius: 6px !important;
  }

  &__cardMediaBgError{
      background: #F50021;
      padding: 35px 30px 30px 30px;
      text-align: center;
  }

  &__cardMediaBgSuccess{
    background: #28CA00;
    padding: 35px 30px 30px 30px;
    text-align: center;
  }

  &__cardMediaBgWarning{
    background: #F18300;
    padding: 35px 30px 30px 30px;
    text-align: center;
  }

  &__text_para{
    margin: 15px 0 16px;
  }

  &__secure_text{
    float: right;
  }

  &__secure_img{
    float: left;
  }

  &__text_center{
  text-align: center;
  }

  &__mt-container{
    margin-top: -5px !important;
  }

  &__mt-10{
    margin-top: 10px !important;
    color: rgba(0, 0, 0, 0.603) !important;
  }

  &__img {
    max-width: 90px;
    max-height: 45px;
    width: 100px;
  }

  &__content {
    max-width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  &__heading {
    margin-top: 20px;
    font-family: var(--font-family-calibri-regular);
    &--success {
      color: #2ecc71;
    }

    &--error {
      color: #e74c3c;
    }

    &--pending {
      color: #F18300;
    }
  }

  &__heading-description {
    font-size: 20px;
    margin-top: 20px;

    &--success {
      color: #2ecc71;
    }

    &--error {
      color: #e74c3c;
    }

    &--pending {
      color: #F18300;
    }
  }

  &__redirect-text {
    font-size: 18px;
    margin-top: 20px;
    text-align: center;

    #{$self}__redirect-link {
      color: var(--color-primary-light);
      cursor: pointer;
    }
  }

  &__offline-method {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;
    background-color: #f5f5f5;
    box-shadow: 2px 2px 2px 2px #c3c3c340;
    padding: 5px 5px;
    margin-top: 15px;
    margin-bottom: 10px;

    &--back-button {
     border: none;
    background-color: var(--color-primary-dark);
    height: 40px;
    cursor: pointer;
    border-radius: 6px;
    color: #fff;
    font-weight: normal;
    font-family: var(--font-family-calibri-regular);
    text-align: center;
    margin-top: 8px !important;
    }
  }

  &__offline-method-text {
    color: #D7002A;
    &--attention {
      font-weight: bold;
      font-size: 20px;
      color: red;
    }

    &--link {
      color: var(--color-primary-light);
      cursor: pointer;
    }
  }
}

.css-46bh2p-MuiCardContent-root:last-child {
    padding-bottom: 0px !important;
}

.mt-2{
   margin-top: 15px !important;
}