:root {
  --color-primary-light: rgb(35, 122, 255);
  --color-primary-dark: rgb(8, 40, 154);
  --color-secondary-green: rgb(212, 249, 187);
  --color-secondary-blue: rgb(25, 26, 53);
  --color-secondary-pink: rgb(239, 125, 124);
  --color-currency-dropdown: rgb(222, 235, 255);
  --color-bg-primary: rgb(237, 237, 237);
  --color-bg-secondary: rgb(255, 255, 255);
  --color-bg-primary-dark: #d3d3d3;
  --border-color: #c3c3c3;
  --text-secondary: #686868;
  --box-shadow: 0px 2px 1px -2px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}
